<template>
  <nav aria-label="Breadcrumb" class="container 2xl:max-w-screen-xl mx-auto gap-x-4 gap-y-8 px-6 mb-5 mt-12 text-left" itemscope="" itemtype="https://schema.org/BreadcrumbList">
    <span v-for="({ path, text, title }, index) in props.pagedata?.breadcrumbs" :key="path">
      <span v-if="index < props.pagedata?.breadcrumbs.length - 1" itemprop="itemListElement" itemscope="" itemtype="https://schema.org/ListItem">
        <a :href="path" itemtype="https://schema.org/Thing" itemprop="item" :title="title">
          <!-- eslint-disable vue/no-v-html -->
          <span itemprop="name" v-html="text" />
          <span> › </span>
        </a>
        <meta itemprop="position" :content="index + 1">
      </span>
      <span v-if="index === props.pagedata?.breadcrumbs.length - 1">
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="text" />
      </span>
    </span>
  </nav>
</template>

<script setup>
const props = defineProps({
  pagedata: {
    type: Object,
    default: null
  }
})
</script>

<style scoped>

</style>
